var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.meta.employment_info.status
    ? _c(
        "div",
        [
          _c(
            "v-alert",
            {
              attrs: {
                border: "top",
                color: "accent",
                icon: "mdi-alert",
                dark: ""
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "applications." +
                        _vm.meta.employment_info.status +
                        "-file-alert"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
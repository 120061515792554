<template>
    <div v-if="meta.employment_info.status">
        <v-alert border="top" color="accent" icon="mdi-alert" dark>
            {{ $t(`applications.${meta.employment_info.status}-file-alert`) }}
        </v-alert>
    </div>
</template>
<script>
export default {
    name: "upload-files-alett",
    props: {
        meta: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>